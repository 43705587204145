import React from "react";

const Banner = () => {
  return (
    <div>
      <img
        src={require("../../assets/images/banner_two.jpg")}
        className="img-fluid w-100"
        alt="product name"
        data-aos="flip-right"
      />
    </div>
  );
};

export default Banner;
