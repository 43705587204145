import React from "react";
import { Container, Row, Col } from "react-bootstrap";
const Mark = () => {
  return (
    <>
      <div className="mark-bg">
        <Container>
          <Row>
            <Col lg="12" className="py-3">
              <div className="text-center regular text-white">
                {" "}
                Copyright © 2024,. Sai Ganesh Crackers Crackers. All rights
                reserved{" "}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Mark;
